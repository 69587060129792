import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import PropTypes from "prop-types";
import GoogleLogin from "react-google-login";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";

import {
    googleAuthenticate,
    googleAuthenticateSuccess,
    googleAuthenticateFailure,
} from "../../../../shared/redux/actions/auth";
import translate from "../../../../shared/hocs/withTranslation";
import "./index.scss";

const GoogleAuthentication = ({ t, ...rest }) => (
    <GoogleLogin
        clientId={process.env.GOOGLE_CLIENT_ID}
        cookiePolicy="single_host_origin"
        render={(renderProps) => (
            <Button
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
                color="primary"
                className="google-authentication"
            >
                <div className="google-authentication__icon">
                    <FontAwesomeIcon icon={faGoogle} />
                </div>
                <span className="google-authentication__text">
                    {t("google_authentication.authenticate")}
                </span>
            </Button>
        )}
        {...rest}
    />
);

export const mapDispatchToProps = {
    onRequest: googleAuthenticate,
    onSuccess: googleAuthenticateSuccess,
    onFailure: googleAuthenticateFailure,
};

GoogleAuthentication.propTypes = {
    onRequest: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onFailure: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

export default compose(translate, connect(null, mapDispatchToProps))(GoogleAuthentication);
