import * as actions from "../actions/auth";
import { getAuthToken } from "../../../utils/auth";

const initialState = {
    user: null,
    isAuthenticating: false,
    isAuthenticated: !!getAuthToken(),
    isGoogleAuthenticated: false,
    googleToken: null,
    googleUser: null,
    authError: null,
};

const authReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case actions.LOGIN.REQUEST:
            return {
                ...state,
                isAuthenticating: true,
            };
        case actions.LOGIN.SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                isAuthenticating: false,
            };
        case actions.AUTHENTICATE_SUCCESS:
            return {
                ...state,
                user: payload.result,
                isAuthenticated: true,
                isAuthenticating: false,
            };
        case actions.LOGIN.FAILURE:
            return {
                ...state,
                isAuthenticated: false,
                isAuthenticating: false,
                authError: payload,
            };
        case actions.LOGOUT_SUCCESS:
            return {
                ...state,
                isAuthenticated: false,
                isAuthenticating: false,
                user: null,
            };
        case actions.GOOGLE_OAUTH_VERIFIED:
            return {
                ...state,
                isGoogleAuthenticated: true,
                googleToken: payload.tokenId,
                googleUser: payload.profileObj,
            };
        case actions.GOOGLE_OAUTH_FAILURE:
        case actions.GOOGLE_OAUTH_RESET:
            return initialState;
        default:
            return state;
    }
};

export default authReducer;
