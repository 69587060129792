import { types } from "../utils/actionCreators";

const NAMESPACE = "@@arnold/auth";

export const LOGIN = types("auth", "LOGIN");

export const loginSuccess = (payload, listenerId) => ({
    type: LOGIN.SUCCESS,
    payload,
    meta: {
        listenerId,
    },
});

export const loginFailure = (payload, listenerId) => ({
    type: LOGIN.FAILURE,
    payload,
    error: true,
    meta: {
        listenerId,
    },
});

export const GOOGLE_OAUTH_REQUEST = `${NAMESPACE}/GOOGLE_OAUTH_REQUEST`;
export const googleAuthenticate = () => ({
    type: GOOGLE_OAUTH_REQUEST,
});

export const GOOGLE_OAUTH_SUCCESS = `${NAMESPACE}/GOOGLE_OAUTH_SUCCESS`;
export const googleAuthenticateSuccess = (payload) => ({
    type: GOOGLE_OAUTH_SUCCESS,
    payload,
});

export const GOOGLE_OAUTH_VERIFIED = `${NAMESPACE}/GOOGLE_OAUTH_VERIFIED`;
export const googleAuthenticationVerified = (payload) => ({
    type: GOOGLE_OAUTH_VERIFIED,
    payload,
});

export const GOOGLE_OAUTH_RESET = `${NAMESPACE}/GOOGLE_OAUTH_RESET`;
export const googleAuthenticationReset = () => ({
    type: GOOGLE_OAUTH_RESET,
});

export const GOOGLE_OAUTH_FAILURE = `${NAMESPACE}/GOOGLE_OAUTH_FAILURE`;
export const googleAuthenticateFailure = (payload) => ({
    type: GOOGLE_OAUTH_FAILURE,
    payload,
});

export const LOGOUT_REQUEST = `${NAMESPACE}/LOGOUT_REQUEST`;
export const logout = () => ({
    type: LOGOUT_REQUEST,
});

export const LOGOUT_SUCCESS = `${NAMESPACE}/LOGOUT_SUCCESS`;
export const logoutSuccess = () => ({
    type: LOGOUT_SUCCESS,
});

export const LOGOUT_FAILURE = `${NAMESPACE}/LOGOUT_FAILURE`;
export const logoutFailure = (errors) => ({
    type: LOGOUT_FAILURE,
    errors,
});

export const AUTHENTICATE_REQUEST = `${NAMESPACE}/AUTHENTICATE_REQUEST`;
export const authenticate = () => ({
    type: AUTHENTICATE_REQUEST,
});

export const AUTHENTICATE_SUCCESS = `${NAMESPACE}/AUTHENTICATE_SUCCESS`;
export const authenticateSuccess = (payload) => ({
    type: AUTHENTICATE_SUCCESS,
    payload,
});

export const AUTHENTICATE_FAILURE = `${NAMESPACE}/AUTHENTICATE_FAILURE`;
export const authenticateFailure = (errors) => ({
    type: AUTHENTICATE_FAILURE,
    errors,
});
