import React from "react";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileInvoice } from "@fortawesome/free-solid-svg-icons";
import LoginForm from "../../components/LoginForm";
import GoogleUser from "../../components/GoogleUser";
import GoogleAuthentication from "../../components/GoogleAuthentication";
import CenteredContainer from "../../../../shared/components/CenteredContainer";
import { LOGIN } from "../../../../shared/redux/actions/auth";
import useAsyncAction from "../../../../shared/hooks/useAsyncAction";

import "./index.scss";

const LoginScreen = () => {
    const { isAuthenticating, googleUser, isGoogleAuthenticated } = useSelector(
        (state) => state.auth,
    );
    const onLogin = useAsyncAction(LOGIN);

    return (
        <CenteredContainer>
            <div className="login">
                <div className="login__header">
                    <div className="login__icon">
                        {googleUser ? (
                            <GoogleUser googleUser={googleUser} />
                        ) : (
                            <FontAwesomeIcon
                                className="brand bg-primary"
                                icon={faFileInvoice}
                                size="8x"
                            />
                        )}
                    </div>
                </div>
                <div className="login__content">
                    {isGoogleAuthenticated ? (
                        <LoginForm onSubmit={onLogin} isLoginLoading={isAuthenticating} />
                    ) : (
                        <GoogleAuthentication />
                    )}
                </div>
            </div>
        </CenteredContainer>
    );
};

export default LoginScreen;
