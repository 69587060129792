import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { googleAuthenticationReset } from "../../../../shared/redux/actions/auth";
import "./index.scss";

const GoogleUser = ({ googleUser, handleGoogleReset }) => (
    <div className="google-user bg-primary">
        <FontAwesomeIcon
            className="google-user__clear"
            icon={faTimesCircle}
            onClick={handleGoogleReset}
        />
        {googleUser.imageUrl ? (
            <img
                className="google-user__image"
                src={googleUser.imageUrl}
                title={googleUser.name}
                alt={googleUser.name}
            />
        ) : (
            <FontAwesomeIcon
                className="google-user__image"
                icon={faUserCircle}
                title={googleUser.name}
            />
        )}
    </div>
);

export const mapDispatchToProps = {
    handleGoogleReset: googleAuthenticationReset,
};

GoogleUser.propTypes = {
    handleGoogleReset: PropTypes.func.isRequired,
    googleUser: PropTypes.shape({ imageUrl: PropTypes.string, name: PropTypes.string }).isRequired,
};

export default connect(null, mapDispatchToProps)(GoogleUser);
