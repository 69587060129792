import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import rootReducer from "./reducers";
import rootSaga from "./sagas";
import channelSagas from "./sagas/channels";
import createListener from "./middlewares/promiseListener";
import { api as accountingApi } from "../../services/accounting/api";
import { api as dataEnteringApi } from "../../services/dataEntering/api";
import { createReduxHistory, routerMiddleware } from "./router/context";
import * as Sentry from "@sentry/react";
const reduxPromiseListener = createListener();

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

export function configureAppStore() {
    const sagaMiddleware = createSagaMiddleware();
    const channelsSagaMiddleware = createSagaMiddleware();

    const middlewares = [
        sagaMiddleware,
        channelsSagaMiddleware,
        routerMiddleware,
        reduxPromiseListener.middleware,
        accountingApi.middleware,
        dataEnteringApi.middleware,
    ];

    const store = configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) => [
            ...getDefaultMiddleware({
                serializableCheck: {
                    ignoredActions: [
                        "@@fetch/REQUEST",
                        "@@arnold/channel/CONNECT",
                        "@@arnold/channel/DISCONNECT",
                        "@@arnold/auth/GOOGLE_OAUTH_VERIFIED",
                        "@@arnold/auth/GOOGLE_OAUTH_SUCCESS",
                        "@@arnold/accounting/FETCH",
                        "@@arnold/auth/LOGIN_FAILURE",
                        "@notify/ENQUEUE_NOTIFICATION",
                    ],
                },
            }),
            ...middlewares,
        ],
        enhancers: [sentryReduxEnhancer],
    });

    store.injectedReducers = {};

    store.injectReducer = (key, injectedReducer) => {
        store.injectedReducers[key] = injectedReducer;
        store.replaceReducer(rootReducer(store.injectedReducers));
        return store;
    };

    sagaMiddleware.run(rootSaga);
    channelsSagaMiddleware.run(channelSagas);

    return store;
}

export const promiseListener = reduxPromiseListener;
export const store = configureAppStore();
export const history = createReduxHistory(store);
